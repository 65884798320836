import io from 'socket.io-client'
import VueCookie from 'vue-cookie';

let socketIo = io(process.env.VUE_APP_BACKEND_URL, {
    transports: ['websocket'],
    upgrade: false,
    origin: '*',
    auth: { token: VueCookie.get('authToken') }
})

const reconnectSocket = () => {
    // Check if there's already an active connection
    if (socketIo && socketIo.connected) {
        return socketIo;
    }

    // If not connected or socket is null, establish a new connection
    socketIo = io(process.env.VUE_APP_BACKEND_URL, {
        transports: ['websocket'],
        upgrade: false,
        origin: '*',
        auth: { token: VueCookie.get('authToken') },
    });

    return socketIo;
};

export default reconnectSocket;